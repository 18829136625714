<template>
  <div class="ComplaintList">
    <van-nav-bar
      title="投诉与建议"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="content_from">
      <van-form @submit="onSubmit">
        <van-field name="categoryId" :border="false">
          <template #input>
            <van-radio-group v-model="categoryId" direction="horizontal">
              <van-radio :name="1" checked-color="#EE0A24">投诉客服</van-radio>
              <van-radio :name="2" checked-color="#EE0A24">表扬客服</van-radio>
              <van-radio :name="3" checked-color="#EE0A24">投诉品牌</van-radio>
              <van-radio :name="4" checked-color="#EE0A24">低价投诉</van-radio>
              <van-radio :name="5" checked-color="#EE0A24">寻求合作</van-radio>
              <van-radio :name="6" checked-color="#EE0A24">意见与建议</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field
          class="textarea_class"
          v-model="content"
          type="textarea"
          name="content"
          :border="false"
          placeholder="详细描述您的问题"
          rows="3"
          :rules="[{ required: true, message: ' ' }]"
        />
        <van-field :border="false" style="padding-bottom: 0;" label="补充图片" readonly></van-field>
        <van-field name="uploader" :border="false">
          <template #input>
            <van-uploader
              v-model="uploader"
              :after-read="afterRead"
              :before-read="beforeRead"
              :before-delete="beforeDelete"  />
          </template>
        </van-field>
        <div style="margin: 16px;">
          <van-button round block type="danger" :loading="btnloding" :native-type="btnloding ? 'button' : 'submit'">
            提交
          </van-button>
        </div>
      </van-form>
    </div>
    <div class="content_list">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="getlist"
        :immediate-check="false"
      >
        <div
          class="list_cell"
          v-for="(item, index) in List"
          :key="index"
          @click="details(item.id)"
        >
          <van-cell :border="false">
            <template #title>
              <div class="van-multi-ellipsis--l2">{{ item.content }}</div>
            </template>
            <template #label>
              <div>{{ item.createtime_str }}</div>
            </template>
            <template #right-icon>
              <div class="van-cell__right_icon">
                {{ item.status_str }}
                <div v-if="item.weidu > 0" style="color: red;font-size: 10px;">有新回复</div>
              </div>
            </template>
          </van-cell>
        </div>
      </van-list>
    </div>
  </div>
</template>
<script>
export default {
  name: "ComplaintList",
  data() {
    return {
      screenWidth: document.body.clientWidth, // 屏幕宽
      screeHeight: document.body.clientHeight, // 屏幕高
      shareid: 0,
      merchid: 0,
      CurrSize: 10,
      CurrOffset: 0,
      List: [],
      loading: false,
      finished: false,
      content: "", // 反馈内容
      category: "", // 分类
      uploader: [], // 上传文件
      pic: [],
      categoryId: 0,
      show: false,
      btnloding: false,
      pid: 0,
    };
  },
  mounted() {
    if (this.$route.query.shareid) {
      this.shareid = this.$route.query.shareid;
    } else if (localStorage.getItem("DK_MEMBERID")) {
      this.shareid = localStorage.getItem("DK_MEMBERID");
    }
    this.merchid = this.$route.query.merchid ? this.$route.query.merchid : 0;
    this.$parent.getmerchname(this.merchid, "投诉与建议");
    // this.CurrOffset = 0;
    // this.List = [];
    this.init();
  },
  methods: {
    init() {
      this.getlist();
    },
    // 返回上一页
    onClickLeft() {
      this.$router.back();
    },
    // 获取历史提交数据
    getlist() {
      let _that = null;
      _that = this;
      _that.$axios.defaults.headers["Content-Type"] =
        "application/x-www-form-urlencoded";
      _that.$axios.defaults.headers["Authorization"] = localStorage.getItem(
        "DK_UID"
      );
      _that.$axios
        .post(
          _that.$store.state.domain + "web/feedback/get_list",
          _that.$qs.stringify({
            size: _that.CurrSize,
            offset: _that.CurrOffset
          })
        )
        .then(res => {
          console.log(res);
          _that.loading = false;
          if (res.data.code == 100000) {
            if (_that.CurrOffset == 0) {
              _that.List = res.data.data;
            } else {
              res.data.data.forEach(item => {
                _that.List.push(item);
              });
            }
            if (res.data.data.length >= _that.CurrSize) {
              _that.CurrOffset += _that.CurrSize;
              _that.finished = false;
            } else {
              _that.CurrOffset += res.data.data.length;
              _that.finished = true;
            }
          } else {
            _that.finished = true;
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    // 进入详情
    details(id) {
      this.$router.push({
        name: "ComplaintDetail",
        query: { id: id, shareid: this.shareid, merchid: this.merchid }
      });
    },
    // 提交投诉与建议
    onClickRight() {
      this.$router.push({name: "Complaint", query: { shareid: this.shareid, merchid: this.merchid } });
    },
    // 提交按钮
    onSubmit() {
      console.log("submit");
      let _that = null;
      _that = this;

      if (_that.categoryId <= 0) {
        _that.$toast("请选择反馈类型");
        return false;
      }
      
      _that.btnloding = true; // 防止发生多次误点

      _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      _that.$axios.defaults.headers["Authorization"] = localStorage.getItem("DK_UID");
      _that.$axios
        .post(
          _that.$store.state.domain + "web/feedback/add_suggestion",
          _that.$qs.stringify({
            categoryId: _that.categoryId,
            category: _that.category,
            content: _that.content,
            pic: _that.pic,
            pid: _that.pid
          })
        )
        .then(res => {
          console.log(res);
          if (res.data.code == 100000) {
            _that.$toast(res.data.msg ? res.data.msg : "提交成功");
            _that.btnloding = false;
            _that.$router.go(0);
          }else {
            _that.$toast(res.data.msg ? res.data.msg : "提交失败");
            _that.btnloding = false;
          }
        })
        .catch(err => {
          console.log(err);
          _that.btnloding = false;
        })
    },
    // 文件上传
    // 上传前判断只有返回true 才能上传
    beforeRead() {
      return true;
    },
    // 上传
    afterRead(file, detail) {
      let _that = null;
      _that = this;
      const fordata = new FormData();
      fordata.append('file',file.file);
      fordata.append('dir','image/tousu/');
      fordata.append('merchid',_that.merchid);
      _that.$axios.defaults.headers["Content-Type"] =  "multipart/form-data";
      _that.$axios.post(
        "https://api.midbest.cn/admin/image/shangchuan",
        fordata
      ).then( response =>{
        _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
        if (response.data && response.data.code == 100000) {
          _that.pic.push(response.data.img_url);
          console.log(_that.pic);
        }else {
          _that.$toast(response.data.msg ? response.data.msg : "上传失败");
        }
      }).catch( error =>{
        _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
        console.log(error);
      })
    },
    beforeDelete(file, detail) {
      this.uploader.splice(detail.index, 1);
      this.pic.splice(detail.index, 1);
    },
  }
};
</script>
<style lang="less">
.ComplaintList {
  width: 100%;
  min-height: 100%;
  overflow: hidden;
  .van-nav-bar .van-icon {
    color: #333;
  }
  .van-nav-bar__right .van-nav-bar__text {
    color: #EE0A24;
  }
  .content_from {
    margin: 10px;
    border: 1px solid #eee;
    .van-form {
      .van-cell {
        padding-bottom: 0;
      }
      .van-radio {
        min-height: 40px;
        line-height: 40px;
      }
      .van-field__label {
        width: 65px;
        text-align: left;
      }
      .van-field__value {
        flex:1;
        .van-field__body {
          height: 90%;
          .van-field__control {
            min-height: 100%;
          }
        }
      }
      .textarea_class {
        .van-field__value {
          border: 1px solid #eee;
          padding: 5px 5px 10px 5px;
        }
        .van-field__error-message {
          line-height: 20px;
        }
      }
    } 
  }
  .content_list {
    padding-bottom: 60px;
    .list_cell {
      padding: 0;
      overflow: hidden;
      border-bottom: 1px solid #eee;
      .van-cell {
        .van-cell__title {
          flex: 1;
          text-align: left;
        }
        .van-cell__right_icon {
          margin-left: 5px;
          color: #07c160;
        }
        .van-cell__label {
          .van-image {
            margin-right: 5px;
            &:last-of-type {
              margin-right: 0;
            }
          }
        }
      }
    }
  }
}
</style>